.mainContainer {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	/* justify-content: space-between; */
	color: #fff;
	padding-top: var(--header-height);
	font-size: 20px;
	&>h2 {
		margin-top: 24px;
		font-size: 28px;
		font-weight: initial;
		@media screen and (max-width:360px) {
			display: none;
		}
	}
	&>img {
		border-radius: 16px;
		width: 360px;
		height: 360px;
		background-color: var(--color-lightest);
		@media screen and (max-width:360px) {
			width: 100vw;
			height: auto;
			border-radius: 0;
		}
		@media screen and (max-width:280px) {
		}
	}
	&>.mainMenu {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		margin-top: 32px;
		&>.list {
			width: 100%;
			display: flex;
			justify-content: center;
			gap: 16px;
			& .menu {
				padding: 16px 0;
				gap: 8px;
				width:144px;
				height:144px;
				font-size: 18px;
				font-weight: bold;
				text-align: center;
				flex-direction: column;
				justify-content: flex-start;
				& h3 {
					white-space: pre-wrap;
					font-size: inherit;
					font-weight: inherit;
				}
				& img {
					width:64px;
					height:64px;
				}
			}
			@media screen and (max-width:280px) {
				flex-direction: column;
				& .menu {
					width: 100%;
					height: 96px;
					font-size: 14px;
					flex-direction: row;
					padding: 16px;
					&>h3 {
						flex:1;
					}
				}
			}
			@media screen and (max-width:180px) {
				align-items: center;
				& .menu {
					width:96px;
					height: 96px;
					&>h3 {
						display: none;
					}
				}
			}
		}
		@media screen and (max-width:360px) {
			margin-top: 16px;
		}
		@media screen and (max-width:280px) {
			width: 100%;
		}
	}
	@media screen and (max-width:360px) {
		font-size: 16px;
	}
	@media screen and (max-width:280px) {
		width: 100%;
		padding: 0 16px;
		padding-top: var(--header-height);
	}
}

.youtube {
	@media (pointer:fine){
		&:hover>svg {
			color:#c4302b;
		}
	}
}

.github{
	@media (pointer:fine){
		&:hover>svg {
			color:#000;
		}
	}
}