header {
	position: fixed;
	background: var(--color-light);
	color: var(--color-dark);
	display: flex;
	width:100%;
	left: 0;
	top:0;
	z-index: var(--z-header);
	height: 40px;
	align-items: center;
	justify-content: space-between;
	font-family: 'Malgun Gothic';
	text-shadow: none;
	padding: 8px;
	@media screen and (max-width:360px) {
		justify-content: center;
	}
}

header>.titleAndRoutes {
	display: flex;
	align-items: center;
	gap: 16px;
	user-select: none;
	&>a>.title {
		font-weight: 600;
		font-size:20px;
		&>img {
			width:100%;
			height:100%;
			display:none;
		}
		@media screen and (max-width:600px) {
			font-size: 16px;
		}
		@media screen and (max-width:360px) {
			width: 32px;
			height: 32px;
			font-size: 0;
			&>img {
				display: block;
			}
		}
	}
	&>.routes {
		font-weight: bold;
		&>.route {
			text-decoration: underline;
			position: relative;
			&.last {
				text-decoration: none;
				text-shadow: 2px 2px 0 #0003;
				color: white;
			}
			&::before {
				display: block;
				position: absolute;
				content: '/';
				font-weight: bold;
				width:16px;
				height: 32px;
				top:0;
				left:-16px;
				text-align: center;
			}
		}
		@media screen {
			display: flex !important;
			align-items: center !important;
			gap: 16px;
		}
		/* 480px에서 축소 */
		@media screen and (max-width:600px) {
			font-size:12px;
		}
		/* 360px에서 비표시 */
		@media screen and (max-width:360px) {
			display: none !important;
		}
	}
}

header>.subtitle {
	font-size:16px;
	font-weight:100;
}