.unitContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 64px;
	/* 디스크립션 왼쪽으로 밀착 */
	@media screen and (max-width:1280px) {
		&:not(.single) {
			width: initial;
			align-items: flex-start;
		}
	}
	/* 검색창 상단으로 */
	@media screen and (max-width:960px) {
		width: 100%;
		gap: 32px;
		&:not(.single) {
			align-items: center;
		}
	}
	/* 패딩제거, 컨트롤러 찰싹붙이기 */
	@media screen and (max-width:600px) {
		padding: 16px;
		gap: 0;
		& .unitGridContainer {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
	}
}

.unitDescriptionContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
}

.unitDescription {
	display: flex;
	min-width: 280px;
	&>.left {
		flex: 1;
		&>.top {
			display: flex !important;
			gap: 16px;
			align-items: center !important;
		}
	}
	&>.right {
		display: flex !important;
		flex: 1;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-start;
		gap: 16px;
		@media screen and (max-width:600px) {
			justify-content: center;
		}
		&>p {
			height: 64px;
			display: flex !important;
			align-items: center;
			flex-direction: row;
			justify-content: flex-start;
			@media screen and (max-width:320px) {
				display: none !important;
			}
		}
		/* &>div {
			width: 64px;
			height: 64px;
		} */
	}
	@media screen and (max-width:768px) {
		/* 셀렉터 우선순위 이슈때문에 더 붙여줌 */
		/* &.descriptionBox {
			width: calc(64px * 8);
			flex-direction: column;
		} */
	}
	@media screen and (max-width:600px) {
		&.descriptionBox {
			flex-direction: column;
			width: 100%;
		}
	}
}

.unitGridContainer {
	display: grid;
	grid-template-columns: repeat(8, 64px);
}

.unitSingleContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	gap: 32px;
	&>.btn-container {
		display: flex;
		gap: 16px;
		justify-content: center;
		& .icon-button {
			width: 64px;
		}
	}
	@media screen and (max-width:768px) {
		gap: 16px;
	}
}

.unit-single-back-div {
	margin-top: 32px;
	width:64px;
	height:32px;
}