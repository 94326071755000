nav {
	position:fixed;
	left:0px;
	top: var(--header-height);
	width: var(--nav-width);
	height: calc(100vh - var(--header-height));
	/* min-width:48px; */
	display: block;
	z-index: var(--z-nav);
	box-shadow: 0px 0px 0px #0003;
	overflow: hidden;
	background-color: var(--color-gray);
	/* 320에서 가로배치 */
	@media screen and ((max-width:320px) or (pointer:none)) {
		width: 100%;
		height: var(--nav-width);
		box-shadow: 0px 8px 16px #0003;
	}
	/* 321이상에서 마우스 호버 시 내비 확장 */
	@media screen and (min-width:321px) and (pointer:fine) {
		transition: all 0.25s ease;
		&:hover {
			box-shadow: 8px 0px 16px #0003;
			width:256px;
			&>.container {
				& .widget {
					&>p {
						opacity: 1;
					}
				}
			}
		}
	}
	&>.container {
		/* position: absolute; */
		display:flex;
		flex-direction: column;
		width: 256px;
		height: 100%;
		transition: all 0.25s ease;
		overflow-x: hidden;
		padding: 8px;
		gap:8px;
		@media screen and (max-width:320px) {
			flex-direction: row;
			justify-content: center;
			width: 100%;
			height: var(--nav-width);
		}
		/* z-index: 6; */
		& .widget {
			display: flex;
			align-items: center;
			color: #fff;
			/* white-space: nowrap; */
			gap: 8px;
			&>img {
				width:32px;
				height:32px;
			}
			&>p {
				opacity: 0;
				flex: 1;
				transition: opacity 0.25s ease;
				font-size: 16px;
				text-align: left;
				/* 320에서 항목이름 비표시 */
				@media screen and (max-width:320px) {
					display: none;
				}
			}
		}
	}
}