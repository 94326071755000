.abilityContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 64px;
	/* 디스크립션 왼쪽으로 밀착 */
	@media screen and (max-width:1280px) {
		width: initial;
		&:not(.single) {
			align-items: flex-start;
		}
	}
	/* 검색창 상단으로 */
	@media screen and (max-width:960px) {
		width: 100%;
		gap: 16px;
		&:not(.single) {
			padding-top: 16px;
			align-items: center;
		}
	}
	/* 패딩제거, 컨트롤러 찰싹붙이기 */
	@media screen and (max-width:600px) {
		gap: 0;
		/* align-items: center; */
		& .abilityGridContainer {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
		&:not(.single) {
			padding: 16px;
		}
	}
	/* 싱글일때 상단여백 제거 */
	@media screen and (max-width:320px) {
		&.single {
			padding-top: calc(64px - var(--nav-width));
		}
	}
}

.abilityDescriptionContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
}

.abilityGridContainer {
	display: grid;
	grid-template-columns: repeat(8, 64px);
}

.abilityDescription{
	/* width: calc(64px * 8); */
	min-width: 280px;
	flex-direction: column;
	&>.top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		/* height: 64px; */
		/* 어빌이름&태그 */
		&>.name-and-tags{
			/* flex: 1; */
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 16px;
			&>.ability-name{
				color:#cccccc;
				font-size: 24px;
			}
			&>.ability-tags{
				color:#ffff00;
				font-size: 16px;
			}
			@media screen and (max-width:600px) {
				flex: 1;
				flex-direction: column;
				gap: 8px;
				white-space: nowrap;
				min-width: 92px;
				padding: 4px 0;
				&>.ability-name {
					text-overflow: ellipsis;
					overflow-x: hidden;
					font-size: 18px;
				}
				&>.ability-tags {
					text-overflow: ellipsis;
					overflow-x: hidden;
					font-size: 12px;
				}
			}
			@media screen and (max-width:400px) {
				width: calc(240px - 64px);
			}
		}
		/* 우상단 쿨다운&마나(사거리) */
		&>.cooldown-and-manacost{
			width: 114px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 8px;
			&>div{
				width:100%;
				display: flex;
				gap: 8px;
				align-items: center;
				&>img{
					width: 24px;
					height: 24px;
					@media screen and (max-width:600px) {
						width: 20px;
						height: 20px;
					}
				}
				&>p{
					flex: 1;
					color:#ffffff;
					text-align: left;
				}
			}
		}
		/* 600에서 마나&쿨다운 밑줄로 내리기 */
		@media screen and (max-width:600px) {
			width:100%;
			position: relative;
			&>.cooldown-and-manacost{
				right: 0;
				bottom: -32px;
				position: absolute;
				/* width:50%; */
				flex-direction: row;
				justify-content: flex-end;
				&>div {
					justify-content: flex-end;
					&>p {
						text-align: right;
						padding-right: 8px;
					}
				}
			}
		}
	}
	&>.bottom{
		margin-top:16px;
		text-align: left;
		color:#cccccc;
		&>.cast-type-and-custom-cost {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			&>.cast-type{
				color: #00ffff;
			}
			@media screen and (max-width:600px) {
				flex-direction: column;
				justify-content: initial;
				gap: 8px;
			}
		}
		&>.tooltip{
			margin-top:16px;
			& img{
				width: 24px; /* 폰트 크기와 동일한 크기로 설정 */
				height: 24px; /* 폰트 크기와 동일한 크기로 설정 */
				vertical-align: middle; /* 이미지를 텍스트의 중앙에 맞춤 */
				@media screen and (max-width:600px) {
					width: 20px;
					height: 20px;
				}
			}
			& .unitinfo{
				text-decoration: underline;
				color:#ffcc99;
				&:visited{
					text-decoration: inherit;
					color:#ffcc99;
				}
				&:active{
					text-decoration: underline;
					color:#ffcc99;
				}
			}
		}
		&>.statbonus{
			display:flex;
			margin-top:16px;
			height: 32px;
			text-align: left;
			align-items: center;
			gap: 16px;
			&>p{
				display: flex;
				color:#00ffff;
				text-align: left;
				align-items: center;
			}
			&>img{
				height:32px;
			}
			@media screen and (max-width:600px) {
				height: 24px;
				margin-top: 8px;
				&>img {
					width: 24px;
					height: 24px;
				}
			}
		}
	}
	@media screen and (max-width:768px) {
		/* 셀렉터 우선순위 이슈때문에 더 붙여줌 */
		&.descriptionBox {
			width: calc(64px * 8);
		}
	}
	@media screen and (max-width:600px) {
		&.descriptionBox {
			width: 100%;
		}
	}
}

.abilitySingleContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	gap: 32px;
	&>.btn-container {
		display: flex;
		gap: 16px;
		justify-content: center;
		& .icon-button {
			width: 64px;
		}
	}
	@media screen and (max-width:600px) {
		gap: 16px;
	}
}

.abilityMixContainer {
	display: flex;
	flex-direction: row;
	width: initial;
	gap: 32px;
	&>.descriptionBox {
		width:initial;
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 8px;
		&>.title {
			display: flex;
			gap: 8px;
			align-items: center;
		}
	}
	@media screen and (max-width:768px) {
		flex-direction: column;
		gap: 16px;
	}
}