/* 좌측 내비,우측 메인 */
.mid {
	position: relative;
	padding-left: var(--nav-width);
	padding-top: var(--header-height);
	&.hideNav {
		padding-left:0;
	}
	/* 내비세로배치 */
	@media screen and (max-width:320px) {
		padding-left: 0;
		padding-top: calc(var(--header-height) + var(--nav-width));
	}
}