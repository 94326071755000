/*컨트롤러(우측상단검색창)*/
.controller {
	position:fixed;
	top:calc(var(--header-height) + 16px);
	right:16px;
	display: flex;
	flex-direction: column;
	background-color: var(--color-gray);
	border-radius: 8px;
	padding:16px;
	color:#ffffff;
	z-index: var(--z-controller);
	min-width:256px;
	& svg.favorite {
		color: #999;
		&.active {
			color: #ffcc00;
		}
	}
	/*상단 뷰모드 컨테이너*/
	&>.radio {
		display: flex;
		gap: 8px;
		margin-bottom: 16px;
	}
	/*검색콘솔에 수직으로 깔리는 요소들*/
	&>.item {
		height: 24px;
		display: flex;
		align-items: center;
		&>.name {
			width:72px;
			min-width:72px;
			text-align: left;
			position: relative;
			&::before {
				position: absolute;
				content: ':';
				display: block;
				right:4px;
				top: -2px;
				user-select: none;
				/* vertical-align: middle; */
			}
		}
		&>input[type="checkbox"] {
			text-align: center;
			height:16px;
		}
		&>select{
			flex:1;
			min-width:0;
		}
		&>input[type="text"] {
			flex:1;
			min-width:0;
		}
	}
	/* 하단버튼스페이스 */
	&>.buttonSpace {
		margin-top: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@media screen and (max-width:960px) {
		width: calc(64px * 8);
		position: static;
	}
	@media screen and (max-width:600px) {
		background-color: var(--color-dark-gray);
		box-shadow: none;
		width: 100%;
		top: 0;
		right: 0;
	}
	@media screen and (max-width:360px) {
		&>.radio {
			justify-content: center;
		}
	}
}