@import url('https://thesandfox.github.io/css/mycolor.css');
@font-face {
	font-family: 'W2002'; /* 폰트 이름 설정 */
	src: url('./css/2002.ttf') format('truetype'); /* 폰트 파일 경로 설정 */
}

:root {
	--z-controller: 2;
	--z-nav: 3;
	--z-header: 4;
	--header-height: 40px;
	--nav-width: 48px;
}

/*RESET*/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'W2002', sans-serif;
	text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
	background-color: var(--color-dark-gray);
}

ul, li, ol {
	list-style: none;
}

a {
	text-decoration: none;
	color:inherit;
	&:visited {
		color:inherit;
	}
	&:active {
		color:inherit;
	}
	&.disabled {
		pointer-events: none;
	}
}

img {
	user-select: none;
}


i {
	display: flex;
	align-items: center;
}

::-webkit-scrollbar {
	position: absolute;
	z-index: 200;
	width:8px;
	height:8px;
}

::-webkit-scrollbar-track {
	background-color: #686046;
}

::-webkit-scrollbar-thumb {
	background-color: #ffdd6f;
	transition: background-color 0.25s ease;
	border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #fff1c3;
}

/* COMMON */

.row {
	display: flex;
	flex-direction: row;
}

.col {
	display: flex;
	flex-direction: column;
}

.rel {
	position:relative;
}

.abs {
	position:absolute;
}

.pointer {
	cursor: pointer;
}

.non-focus {
	pointer-events: none;
}

.hidden {
	visibility: hidden;
}

.white {
	color:white;
}

.fill {
	position:absolute;
	width:100%;
	height:100%;
	left:0px;
	top:0px;
}

.highlight {
	position:absolute;
	width:100%;
	height:100%;
	left:0px;
	top:0px;
	opacity: 33%;
	background-color:#ffffff;
	visibility: hidden;
}

@media (pointer:fine) {
	*:hover>.highlight {
		visibility: visible;
	}
}

/*폰트설정*/
.w3font {
	font-family: 'W2002', sans-serif;
	text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
}

.font16 {
	font-size: 16px;
}

.font24 {
	font-size:24px;
}

/*아이콘버튼*/
.icon-button {
	border-radius: 8px;
	background-color: var(--color-light);
	align-items: center;
	justify-content: center;
	display: flex;
	cursor: pointer;
	min-width:32px;
	height:32px;
	color: var(--color-dark);
	text-shadow: none;
	user-select: none;
	border: 2px solid var(--color-light);
	&.hover {
		background-color: var(--color-dark);
		border: 2px solid var(--color-light);
		color: var(--color-light);
		&>svg {
			color: var(--color-light);
		}
	}
	@media (pointer:fine) {
		&:hover {
			background-color: var(--color-dark);
			border: 2px solid var(--color-light);
			color: var(--color-light);
			&>svg {
				color: var(--color-light);
			}
		}
	}
	&>svg {
		color: var(--color-dark);
		text-shadow: none;
		font-size: 16px;
	}
	&.disabled {
		border-color: var(--color-gray);
		background-color: var(--color-light-gray);
		color: var(--color-gray);
		pointer-events: none;
	}
}

.icon-text {
	vertical-align: bottom;
	width:1.25em;
	height:1.25em;
	margin-right: 0.25em;
}

/*텍스트 중간에 들어가는 아이콘버튼*/
.text-button {
	text-align: center;
	align-items: center;
	justify-content: center;
	display: flex;
	cursor: pointer;
	width:1em;
	height:1em;
	&>svg {
		color: inherit;
		/*text-shadow: none;*/
	}
}

.descriptionBox {
	padding:16px;
	background-color: var(--color-gray);
	border-radius: 16px;
	width: 720px;
	display: flex;
	/* flex-direction: column; */
	@media screen and (max-width:960px) {
		width: calc(64px * 8);
	}
	@media screen and (max-width:600px) {
		font-size: 12px;
	}
	@media screen and (max-width:320px) {
		padding: 8px;
	}
}

.bottomRightButtonContainer {
	position: fixed;
	display: flex;
	align-items: flex-end;
	justify-content: right;
	bottom: 8px;
	right: 8px;
	gap: 8px;
	height:48px;
	& .icon-button {
		width:40px;
		height:40px;
		box-shadow: 0 0 8px #0003;
		opacity: 0.66;
		transition: 0.25s;
		&>svg {
			font-size: 24px;
		}
		@media (pointer:fine) {
			&:hover {
				opacity: 1;
			}
		}
	}
	@media screen and (max-width:320px) {
		& .icon-button {
			width:32px;
			height:32px;
			&>svg {
				font-size: 16px;
			}
		}
	}
}
/* 즐찾 */
*:hover>.favoriteButton {
	&.disabled {
		color: #ff9;
		opacity: 1;
	}
}
.favoriteButton {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
	left:0;
	width:24px;
	height:24px;
	background-color: #0009;
	border-radius: 0 0 4px 0;
	cursor: pointer;
	&>svg {
		text-align: center;
		font-size: 16px;
		color: #999;
		&.active {
			color: #fc0;
		}
	}
	&.disabled {
		opacity: 0.75;
		cursor: default;
		pointer-events: none;
		background: none;
		&>svg {
			filter: drop-shadow(2px 2px #0009);
		}
	}
	&.hidden {
		display: none;
	}
	&:hover {
		&>svg {
			color: #fc0;
		}
	}
}
/*64x64 워크아이콘*/
.w3x-icon {
	width:64px;
	height:64px;
	font-size: 0;
	display: flex;
}

.icon-24x {
	width:24px;
	height:24px;
}

/*너비*/
.width-half{
	width:50%;
}

/*높이*/
.h24{
	height: 24px;
}

.h32{
	height: 32px;
}

.h48{
	height: 48px;
}

.h64{
	height: 64px;
}

/*Align*/
.horizon-left {
	text-align: left;
	justify-content: left;
}

.horizon-center {
	text-align: center;
	justify-content: center;
}

.horizon-right {
	text-align: right;
	justify-content: right;
}

.vertical-top {
	align-items: flex-start;
}

.vertical-center {
	align-items: center;
}

.vertical-bottom {
	align-items: flex-end;
}

.bottom-right {
	bottom:0px;
	right:0px
}

/*외곽선*/

.tier0 {
	border:4px solid #cccccc99;
}

.tier1 {
	border:4px solid #ffffff99;
}

.tier2 {
	border:4px solid #00ff0099;
}

.tier3 {
	border:4px solid #0099ff99;
}

.tier4 {
	border:4px solid #cc00ff99;
}

.tier5 {
	border:4px solid #ff990099;
}

.tier-this {
	border:4px solid #ffff0099;
}

.border-fill {
	width:100%;
	height:100%;
	left:0px;
	top:0px;
	position:absolute;
}

.shadow {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

/*패딩, 마진*/
.m-left16 {
	margin-left:16px;
}

.m-right16 {
	margin-right:16px;
}

.m-bottom16 {
	margin-bottom: 16px;
}